import React from "react";

const SobreMi = () => {
    return(
        <div className="container">
          <h1 className="text-center m-2 p-3" id="SobreMi">Un poco sobre mí</h1>
            <div className="row d-flex justify-content-around pb-5">
                
                <div className="col-md-7 p-5 textoPresentacion">
                    <p>Mi nombre es Marilyn Gómez. <br/><b>PsicoTeAyudo</b> es el nombre que le doy al servicio profesional que presto desde mi profesión de Psicóloga, matriculada en el Colegio de Psicólogos de la Provincia de Córdoba MP 12788.
<br/>En mi formación profesional incorporé conocimientos y prácticas de otras disciplinas (teatro, Gestalt, música, biodanza) que permiten ofrecer un servicio de psicóloga portadora de experiencias y vivencias tomadas desde la teoría y práctica de éstas carreras. 
<br/>Atiendo a personas desde los 11 años. Las herramientas y técnicas que pudiera aplicar en el consultorio contemplan las individualidades de cada paciente que allí concurre. </p>
                </div>
                <div className="col-md-5">
                    <a><img  src="./Images/Marilyn.jpg" className="card-img-top" alt="Psicóloga Marilyn Gómex" id="imagenMarilyn"/></a>
                </div>
                
            </div>
        </div>

        )
}

export default SobreMi;